import React from "react"
// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import BigTitle from "../components/titles"
import Container from "../components/container"

const ServicesPage = () => (
  <Layout>
    <Seo title="OGD42 - Services" />
    <Container type="column" center>
      <BigTitle>Services</BigTitle>
    </Container>
  </Layout>
)

export default ServicesPage
