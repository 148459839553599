import React from 'react';
import styled from 'styled-components';

const Container = ({ children, ...rest }) => <StyledContainer {...rest}>{children}</StyledContainer>;

const StyledContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: ${(props) => (props.type === 'row' ? 'row' : 'column')};
  justify-content: ${(props) => (props.center ? 'center' : 'normal')};
  align-items: ${(props) => (props.center ? 'center' : 'normal')};
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export default Container;
